import React from 'react';

export const WhitePapers = [
  {
    title: 'Digital Product Kickstart',
    shortDescription: `Download your free copy of the Digital Product Kickstart guide and find out how you can validate 
    and refine your digital product idea in 3 simple steps.`,
    description:
  <div>
    Building products that people love requires understanding the user. The Product Discovery
    Phase is the key towards bridging the gap between business and end-user.
    <br />
    {' '}
    <br />
    Download your free copy of the
    {' '}
    <strong> Digital Product Kickstart </strong>
    {' '}
    guide and find out how you can
    validate and refine your digital product idea in 3 simple steps.
  </div>,
    image: require('../assets/images/labs/digital.jpg'),
    comingSoon: false,
    page: 'digital-product-kickstart',
    pdf: 'digital-product-kickstart-mcro.pdf',
  }, {
    title: 'The Business Value of UX',
    shortDescription: 'How UX design can help you build a successful business growth strategy',
    image: require('../assets/images/labs/the-business-value-of-ux.jpg'),
    comingSoon: true,
    page: 'the-business-value-of-ux',
  }, {
    title: 'AWS - Architecting for the Cloud',
    shortDescription: 'Best practices for designing architectures that make the most of the AWS platform',
    image: require('../assets/images/labs/aws.jpg'),
    comingSoon: true,
    page: 'aws-architecting-for-the-cloud',
  }
];

export const ProjectCards = [
  {
    link: 'https://github.com/MCROEngineering/snake3d/',
    secondLink: 'https://snake.mcro.tech/',
    title: '3D Snake Game',
    btnName: 'Visit on GitHub',
    secondBtn: 'Play Online',
    docsBtnName: 'VIEW DEMO',
    content: `Rebooting the classic Snake game in 3D using Three.js, putting it all together inside a heavily
     functional RxJS sequence system assembled with Angular.`,
    image: require('../assets/images/labs/snake3d.svg'),
  }, {
    link: 'https://github.com/MCROEngineering/react-native-onfido-sdk',
    title: 'react-native-onfido-sdk',
    btnName: 'Visit on GitHub',
    docsBtnName: '',
    content: `A React Native Wrapper over Onfido Native SDK to help developers easier integrate 
      this identity verification platform into their react-native applications.`,
    image: require('../assets/images/labs/react.png'),
  }, {
    link: 'https://github.com/blockbitsio',
    title: 'blockbits.io',
    btnName: 'Visit on GitHub',
    docsBtnName: 'DOCUMENTATION',
    content: `BlockBits DApp provides innovative mechanisms such as safe funding vaults and secure milestone
     based payments and is designed to become fully independent, maintained and governed by its token holders.`,
    image: require('../assets/images/labs/blockbits.png'),
  }
];
