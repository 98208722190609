import React from 'react';
import PropTypes from 'prop-types';

const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {Object.keys(formErrors).map((fieldName) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <p key={formErrors[fieldName]}>{formErrors[fieldName]}</p>
        );
      }
      return '';
    })}
  </div>
);

FormErrors.propTypes = {
  formErrors: PropTypes.shape({}).isRequired,
};

export default FormErrors;
