import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, Element } from 'react-scroll';

import Layout from './GatsbyLayout';
import Helmet from './Helmet';
import imageSuccess from '../assets/images/svg/success.svg';
import FormErrors from '../util/formErrors';
import getCookie from '../util/getCookie';
import configSendEmail from '../config/sendEmailConfig';

class WhitePaperDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      company: '',
      role: '',
      formErrors: { name: '', email: '' },
      formValid: false,
      successMessage: '',
      errorMessage: '',
      showForm: true,
    };
  }

  handleInputChange = (event) => {
    const { target: { value, name } } = event;

    if (!name) {
      return;
    }

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  renderErrorMessage = nameError => (<FormErrors formErrors={{ nameError }} />);

  renderField = (placeholder, type, name, value, nameError) => (
    <div
      className={classnames({
        'career-form__field': true,
        error: nameError
      })}
    >
      <label htmlFor={name} className="label">{name}</label>
      <input
        className="form-control"
        placeholder={placeholder}
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={this.handleInputChange}
      />
      {nameError ? this.renderErrorMessage(nameError) : null}
    </div>
  );

  handleSuccess = () => {
    this.setState({
      isSending: false,
      name: '',
      email: '',
      company: '',
      role: '',
      formValid: false,
      showForm: false,
      successMessage: 'Email sent!',
      errorMessage: '',
    });
  };

  handleHubspotSubmit = (obj) => {
    const { email, name, company, role } = obj;
    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email
        },
        {
          name: 'firstname',
          value: name
        },
        {
          name: 'company',
          value: company
        },
        {
          name: 'role',
          value: role
        },
      ],
      skipValidation: true,
      context: {
        hutk: getCookie('hubspotutk'),
        pageUri: 'https://mcro.tech/resources/digital-product-kickstart/',
        pageName: 'Digital Product Kickstart',
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to allow MCRO to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: 'I agree to receive marketing communications from MCRO.',
            },
          ],
        },
      },
    };
    fetch(configSendEmail.sendToResourcesFromHubspotURL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((resp) => {
      if (resp.status === 200) {
        console.log('Success - hubspot !!!');
      } else {
        console.log('Error = ', resp);
      }
    }).catch((e) => {
      console.log('Error = ', e);
    });
  };

  handleSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { title, pdf } = this.props;
    const { name, company, email, role } = this.state;

    this.setState({
      isSending: true,
    });

    const body = {
      name,
      email,
      company,
      role,
      file: pdf,
      paperTitle: title,
    };
    this.handleHubspotSubmit({ name, email, company, role });
    fetch(configSendEmail.sendEmailWithAttachmentToUserURL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((resp) => {
      if (resp.status === 200) {
        this.handleSuccess();
      } else {
        this.setState({
          isSending: false,
          errorMessage: 'We could not send the email. Please try again.',
        });
      }
    }).finally(() => {
      this.setState({
        isSending: false,
      });
    })
      .catch(() => {
        this.setState({
          isSending: false,
          errorMessage: 'We could not send the email. Please try again.',
        });
      });
  }

  validateField(fieldName, value) {
    const { state } = this;
    const { formErrors: fieldValidationErrors } = state;
    let valid;
    let formIsValid = true;

    fieldValidationErrors.name = '';

    switch (fieldName) {
      case 'email':
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = valid ? '' : 'Your email is not valid';
        break;
      case 'name': {
        const validLength = value && value.trim().length > 5;
        const validLetters = value && /^[a-zA-Z]*$/g.test(value);

        if (!validLength) {
          fieldValidationErrors.name = 'Your name is too short';
        } else if (!validLetters) {
          fieldValidationErrors.name = 'Your name should not have numbers or symbols';
        }
        break;
      }
      default:
        break;
    }

    Object.keys(fieldValidationErrors).forEach((key) => {
      const element = fieldValidationErrors[key];

      if ((element && element.length) || !state[key] || (typeof state[key] === 'string' && !state[key].length)) {
        formIsValid = false;
      }
    });

    this.setState({
      formErrors: fieldValidationErrors,
      formValid: formIsValid,
    });
  }

  renderForm = () => {
    const {
      name, email, company, role, successMessage, showForm, formValid, isSending, errorMessage,
      formErrors: { email: emailError, name: nameError } } = this.state;
    const { title } = this.props;

    const buttonClasses = !formValid || isSending ? 'button-primary-large-dark font-button-large-bold disabled'
      : 'button-primary-large-dark font-button-large-bold';

    return (
      <form className="career-form">
        {successMessage
          ? (
            <div className="success">
              <img className="success-image" src={imageSuccess} alt="success" title="success" />
              <div className="success-message font-large">
                {successMessage}
              </div>

              <div className="describe font-title2">
                {`Your copy of the "${title}" white paper is on its way. Check your email for the attachment.`}
              </div>
            </div>
          )
          : (
            <div className={classnames({ 'career-form__fields-white-paper': true, hidden: !showForm })}>
              <div className="form-title">
                <h3 className="career-form__title" tabIndex="0">{`Download the "${title}" White Paper`}</h3>
                <div className="career-form__after-title" tabIndex="0">
                  {`Fill out the form below to get your free copy of the "${title}" white paper.`}
                </div>
              </div>
              {this.renderField('Full Name', 'text', 'name', name, nameError)}
              {this.renderField('Email', 'email', 'email', email, emailError)}
              {this.renderField('Company', 'text', 'company', company)}
              {this.renderField('Your role', 'text', 'role', role)}
              <button
                id="career-form-button"
                className={buttonClasses}
                onClick={this.handleSubmit}
              >
                {isSending ? 'Sending...' : 'Get a copy'}
              </button>
            </div>
          )}
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null }
      </form>
    );
  };

  render() {
    const { location, title, page, shortDescription, description, image } = this.props;

    return (
      <Layout location={location}>
        <Helmet
          title={`${title} - Resources | MCRO`}
          description={shortDescription}
          thumbnail="thumbnail"
          canonical
          keywords={title}
          canonicalPage={`resources/${page}`}
        />
        <h1 className="show-for-seo">{shortDescription}</h1>
        <div className="paper main main__inner">
          <div className="paper-info">
            <div className="paper-info-title" tabIndex="0">{title}</div>
            <div className="paper-info-description" tabIndex="0">{description}</div>
            <Link
              className="button-smooth paper-button"
              to="downloadForm"
              smooth
              spy
              offset={-80}
              duration={500}
              activeClass="button-smooth_active"
            >
              I'm interested
            </Link>
            <img className="paper-info-image" src={image} alt={title} title={title} />
          </div>
          <div className="career-details">
            <Element className="main-career-form" name="downloadForm">
              {this.renderForm()}
            </Element>
          </div>
        </div>
      </Layout>
    );
  }
}

WhitePaperDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({}).isRequired,
  shortDescription: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default WhitePaperDetails;
