import React from 'react';
import PropTypes from 'prop-types';
import WhitePaperDetails from '../components/WhitePaperDetails';
import { WhitePapers } from '../data/resources-data';

const WhitePaperPage = ({ location }) => {
  const paper = WhitePapers.find(item => `/resources/${item.page}/` === location.pathname);

  return (
    <WhitePaperDetails
      location={location}
      title={paper.title}
      page={paper.page}
      shortDescription={paper.shortDescription}
      description={paper.description}
      image={paper.image}
      pdf={paper.pdf}
    />
  );
};

WhitePaperPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

WhitePaperPage.defaultProps = {
  data: null
};

export default WhitePaperPage;
